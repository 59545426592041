var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, TextField, Pane, Datepicker, Select, Accordion, IconButton, AccordionSet, Icon, Checkbox, Selection, Row, Col, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import debounce from "lodash/debounce";
import TableComponent from "../../components/TableComponent";
import AuditLogDetail from "./AuditLogDetail"; // Ensure the path is correct
import { fetchAuditLogs } from "../../api/auditService";
import { fetchUserById, fetchUsers } from "../../api/usersService";
import { useIntl } from "react-intl";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var AuditLogsList = function () {
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var intl = useIntl();
    var _a = useState({
        entityName: "",
        entityAction: null,
        id: "",
        entityId: null,
        createDateFrom: null,
    }), filters = _a[0], setFilters = _a[1];
    var _b = useState(true), filterPaneIsVisible = _b[0], setFilterPaneIsVisible = _b[1];
    var _c = useState(null), selectedAuditLog = _c[0], setSelectedAuditLog = _c[1];
    var _d = useState(""), searchTerm = _d[0], setSearchTerm = _d[1]; // Add search term state
    var _e = useState(null), tempEntity = _e[0], setTempEntity = _e[1];
    var _f = useState(""), tempId = _f[0], setTempId = _f[1];
    var _g = useState(null), tempAction = _g[0], setTempAction = _g[1];
    var _h = useState(null), tempUser = _h[0], setTempUser = _h[1];
    var _j = useState(null), tempCreateDateFrom = _j[0], setTempCreateDateFrom = _j[1];
    var _k = useState(null), tempCreateTimeFrom = _k[0], setTempCreateTimeFrom = _k[1];
    var _l = useState(false), isCheckboxChecked = _l[0], setIsCheckboxChecked = _l[1];
    var _m = useState([]), users = _m[0], setUsers = _m[1];
    var _o = useState(""), selectedUser = _o[0], setSelectedUser = _o[1]; // Holds selected user ID
    var _p = useState(false), userLoading = _p[0], setUserLoading = _p[1]; // Loading state for users
    var _q = useState(""), userQuery = _q[0], setUserQuery = _q[1]; // Holds the current input text for user search
    var debouncedSetUserQuery = useCallback(debounce(function (inputValue) {
        setUserQuery(inputValue);
    }, 300), []);
    // Handle input changes in the Selection component for users
    var handleUserInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setUserLoading(true);
                    return [4 /*yield*/, debouncedSetUserQuery(inputValue)];
                case 1:
                    _a.sent(); // Use inputValue for debouncing
                    setUserLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleUserSelection = function (selectedValue) {
        setSelectedUser(selectedValue);
        // Optionally fetch user details if needed based on selectedValue
        // Update formValues or any other relevant state with the selected user details
        setTempUser(selectedValue);
    };
    useEffect(function () {
        var fetchFilteredUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetchedUsers, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!userQuery) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetchUsers(ky, { search: userQuery })];
                    case 1:
                        fetchedUsers = _a.sent();
                        setUsers(fetchedUsers);
                        return [3 /*break*/, 3];
                    case 2:
                        setUsers([]); // Clear user list if no filters are set
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error("Error fetching users:", error_1);
                        setUsers([]); // Clear user list in case of error
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchFilteredUsers();
    }, [userQuery]);
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var auditLogId = queryParams.get("detail");
        if (queryParams.get("entityId")) {
            var fetchUser = function () { return __awaiter(void 0, void 0, void 0, function () {
                var fetchedUser, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, fetchUserById(ky, queryParams.get("entityId") || "")];
                        case 1:
                            fetchedUser = _a.sent();
                            setUsers([fetchedUser]);
                            setSelectedUser(queryParams.get("entityId") || "");
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _a.sent();
                            console.error("Error fetching users:", error_2);
                            setUsers([]); // Clear user list in case of error
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            fetchUser();
        }
        var updatedFilters = {
            entityName: queryParams.get("entityName") || "",
            entityAction: queryParams.get("entityAction"),
            id: queryParams.get("id") || "",
            entityId: queryParams.get("entityId"),
            createDateFrom: queryParams.get("createDateFrom") || null,
            createTimeFrom: queryParams.get("createTimeFrom") || null,
        };
        if (updatedFilters.entityAction == "Všechny")
            updatedFilters.entityAction = "";
        setFilters(updatedFilters);
        if (auditLogId) {
            fetchAuditLogs(ky, updatedFilters).then(function (auditLogs) {
                var auditLog = auditLogs.find(function (p) { return p.id === auditLogId; });
                if (auditLog) {
                    setSelectedAuditLog(auditLog);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var updateUrlWithFilters = function () {
        var params = new URLSearchParams();
        // Add the entity and id filters directly from filters state
        if (tempEntity)
            params.set("entityName", tempEntity);
        if (tempId)
            params.set("entityId", tempId);
        // Use temporary state values for entityAction, user, and createTime
        if (tempAction)
            params.set("entityAction", tempAction);
        if (tempUser)
            params.set("id", tempUser);
        if (tempCreateDateFrom)
            params.set("createDateFrom", tempCreateDateFrom);
        if (tempCreateDateFrom)
            params.set("createTimeFrom", tempCreateTimeFrom);
        // Update the URL with the new search parameters
        history.push({ search: params.toString() });
    };
    var handleClearFilters = function () {
        setFilters({
            entityName: "",
            entityAction: null,
            id: "",
            entityId: null,
            createDateFrom: null,
        });
        setTempEntity("");
        setTempId("");
        setTempAction(null);
        setTempUser(null);
        setTempCreateDateFrom(null);
        setSelectedUser("");
        history.push({ search: "" });
    };
    var _r = useState([]), userOptions = _r[0], setUserOptions = _r[1];
    var isAnyFilterActive = function () {
        return Object.values(filters).some(function (value) { return value; }) || isCheckboxChecked;
    };
    var _s = useQuery(["audit-logs", filters], function () { return fetchAuditLogs(ky, filters); }, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _t = _s.data, auditLogsData = _t === void 0 ? [] : _t, isLoading = _s.isLoading, isError = _s.isError;
    var formatDateToEuropean = function (dateString) {
        var date = new Date(dateString);
        var day = String(date.getDate()).padStart(2, "0");
        var month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        var year = date.getFullYear();
        var hours = String(date.getHours()).padStart(2, "0");
        var minutes = String(date.getMinutes()).padStart(2, "0");
        return "".concat(day, ".").concat(month, ".").concat(year, " ").concat(hours, ":").concat(minutes);
    };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var columns = [
        {
            name: "Entita",
            key: "entityName",
            clickable: true,
            onClick: function (item) {
                var currentParams = new URLSearchParams(location.search);
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedAuditLog(item);
            },
        },
        {
            name: "Uživatel",
            key: "user",
            cellRenderer: function (item) {
                return item.user ? "".concat(item.user.firstName, ", ").concat(item.user.lastName) : "";
            },
        },
        { name: "Akce", key: "entityAction" },
        { name: "ID", key: "id" },
        {
            name: "Čas",
            key: "createTime",
            cellRenderer: function (item) {
                return item.createTime ? formatDateToEuropean(item.createTime) : "";
            },
        },
    ];
    // You can define a source here if necessary, or adjust as needed.
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return auditLogsData; };
    source.resultCount = function () { return auditLogsData.length; };
    source.totalCount = function () { return auditLogsData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Filtrov\u00E1ni", lastMenu: React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } }) },
                React.createElement(Button, { buttonStyle: "none", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters },
                    React.createElement(Icon, { icon: "times-circle-solid" }, "Resetovat v\u0161echno")),
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Filtry", separator: false },
                        React.createElement(Checkbox, { key: "fetch-all", label: "V\u0161echny", checked: isCheckboxChecked, onChange: function (e) {
                                setIsCheckboxChecked(!isCheckboxChecked);
                                var isChecked = e.target.checked;
                                if (isChecked) {
                                    var clearedFilters = {
                                        entityName: "",
                                        entityAction: null,
                                        id: "",
                                        entityId: null,
                                        createDateFrom: null,
                                        createTimeFrom: null,
                                    };
                                    setFilters(clearedFilters);
                                    setTempEntity(""); // Reset temp states if necessary
                                    setTempId("");
                                    setTempAction(null);
                                    setTempUser(null);
                                    setTempCreateDateFrom(null);
                                    setTempCreateTimeFrom(null);
                                    history.push({ search: "" });
                                }
                                else {
                                    // Optionally restore previous filters here
                                }
                                updateUrlWithFilters();
                            } }),
                        React.createElement(Select, { id: "entity-type", label: "Typ Entity", value: tempEntity !== null && tempEntity !== void 0 ? tempEntity : undefined, onChange: function (e) {
                                var newEntity = e.target.value || null;
                                setTempEntity(newEntity);
                            }, dataOptions: [
                                { value: "", label: "Všechny" },
                                { value: "organization", label: "Organization" },
                                { value: "user", label: "User" },
                                { value: "userOrganization", label: "User Organization" },
                                { value: "publisher", label: "Publisher" },
                            ] }),
                        React.createElement(TextField, { placeholder: "ID", value: tempId, onChange: function (e) { return setTempId(e.target.value); } }),
                        React.createElement(Select, { label: "Akce", dataOptions: [
                                { label: "Všechny", value: "" },
                                { label: "Create", value: "create" },
                                { label: "Update", value: "update" },
                                { label: "Delete", value: "delete" },
                            ], value: tempAction !== null && tempAction !== void 0 ? tempAction : undefined, onChange: function (e) {
                                var newAction = e.target.value || null;
                                setTempAction(newAction); // Update temporary state
                            } }),
                        React.createElement(Selection, { label: "User", name: "userSelection", value: selectedUser, disabled: userLoading, onChange: handleUserSelection, asyncFilter: true, onFilter: handleUserInput, dataOptions: users
                                ? users.map(function (user) { return ({
                                    value: user.id,
                                    label: "".concat(user.firstName, " ").concat(user.lastName),
                                }); })
                                : [] }),
                        React.createElement(Row, { style: { margin: '0px' } },
                            React.createElement(Col, null,
                                React.createElement(Datepicker, { label: intl.formatMessage({ id: 'ui-br-users.audit-logs.label.date' }), value: tempCreateDateFrom || "", onChange: function (e, formatted, dateString) { return setTempCreateDateFrom(dateString || null); }, fullWidth: true, backendDateStandard: "YYYY-MM-DD", dateFormat: "DD.MM.YYYY" })),
                            React.createElement(Col, { style: { marginLeft: '5px' } },
                                React.createElement(TextField, { label: intl.formatMessage({ id: 'ui-br-users.audit-logs.label.time' }), placeholder: "HH:MM", value: tempCreateTimeFrom || "", onChange: function (e) { return setTempCreateTimeFrom(e.target.value); } }))),
                        React.createElement(Button, { onClick: function () {
                                // Update the filters state with the temporary values
                                setFilters(function (prevFilters) { return (__assign(__assign({}, prevFilters), { entityName: tempEntity, entityId: tempId, entityAction: tempAction, id: tempUser, createDateFrom: tempCreateDateFrom })); });
                                // Call the updateUrlWithFilters to update the URL
                                updateUrlWithFilters();
                            }, marginBottom0: true }, "Aplikovat"))))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: "Auditn\u00ED Protokoly", paneSub: auditLogsData.length > 0
                    ? "Nalezeno ".concat(auditLogsData.length, " z\u00E1znam\u016F")
                    : "Zadejte vyhledávací kritéria pro zahájení vyhledávání" },
                React.createElement(TableComponent, { data: auditLogsData, columns: columns, pageSize: 30, hasDataFetched: isAnyFilterActive, searchTerm: searchTerm, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedAuditLog && (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Audit Log Detail", onClose: function () {
                    setSelectedAuditLog(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() });
                }, dismissible: true },
                React.createElement(AuditLogDetail, { auditLog: selectedAuditLog, setSelectedAuditLog: setSelectedAuditLog }))))));
};
export default AuditLogsList;
