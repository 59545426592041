var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Pane, Button, Row, Col, PaneFooter, ErrorModal, Modal } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { useMutation } from "react-query";
import { changeUserPassword, fetchCurrentUser } from "../../api/usersService";
function MyProfilePassword() {
    var _this = this;
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState(""), currentPassword = _a[0], setCurrentPassword = _a[1];
    var _b = useState(""), newPassword = _b[0], setNewPassword = _b[1];
    var _c = useState(false), showCurrentPassword = _c[0], setShowCurrentPassword = _c[1];
    var _d = useState(false), showNewPassword = _d[0], setShowNewPassword = _d[1];
    var _e = useState(false), errorModalOpen = _e[0], setErrorModalOpen = _e[1];
    var _f = useState(""), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = useState(null), userId = _g[0], setUserId = _g[1];
    var _h = useState(false), shouldChangePassword = _h[0], setShouldChangePassword = _h[1];
    var isFetchingUserId = useRef(false);
    var handleCurrentPasswordChange = function (e) {
        setCurrentPassword(e.target.value);
    };
    var handleNewPasswordChange = function (e) {
        setNewPassword(e.target.value);
    };
    var _j = useState({
        currentPassword: "",
        newPassword: ""
    }), errors = _j[0], setErrors = _j[1];
    var validateForm = function () {
        var newErrors = {
            currentPassword: "",
            newPassword: ""
        };
        if (!currentPassword) {
            newErrors.currentPassword = "Heslo je povinné.";
        }
        else if (currentPassword.length < 8) {
            newErrors.currentPassword = "Heslo musí mít alespoň 8 znaků.";
        }
        if (!newPassword) {
            newErrors.newPassword = "Heslo je povinné.";
        }
        else if (newPassword.length < 8) {
            newErrors.newPassword = "Heslo musí mít alespoň 8 znaků.";
        }
        setErrors(newErrors);
        return Object.values(newErrors).every(function (error) { return error === ""; });
    };
    useEffect(function () {
        var fetchUserId = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (isFetchingUserId.current)
                            return [2 /*return*/];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        isFetchingUserId.current = true;
                        return [4 /*yield*/, fetchCurrentUser(ky)];
                    case 2:
                        response = _b.sent();
                        if ((_a = response === null || response === void 0 ? void 0 : response.user) === null || _a === void 0 ? void 0 : _a.id) {
                            setUserId(response === null || response === void 0 ? void 0 : response.user.id);
                            setShouldChangePassword(response === null || response === void 0 ? void 0 : response.user.shouldChangePassword);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        console.error("Error fetching user ID:", error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        isFetchingUserId.current = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        if (!userId) {
            fetchUserId();
        }
    }, [ky, userId]);
    var changePassword = useMutation(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changeUserPassword(ky, userId !== null && userId !== void 0 ? userId : "", {
                        currentPassword: currentPassword,
                        newPassword: newPassword
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, {
        onSuccess: function () {
            history.push("/brusers/my-profile");
        },
        onError: function (error) {
            console.error("Failed to change password:", error);
        }
    }).mutateAsync;
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, changePassword()];
                case 2:
                    _a.sent();
                    console.log("Password changed successfully");
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error("Error changing password:", error_2);
                    setErrorMessage("Do\u0161lo k chyb\u011B p\u0159i aktualizaci hesla: ".concat(error_2));
                    setErrorModalOpen(true);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var renderForm = function () { return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { marginBottom: "20px" } },
                React.createElement("strong", null, "Sou\u010Dasn\u00E9 Heslo"),
                React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                    React.createElement("input", { type: showCurrentPassword ? "text" : "password", value: currentPassword, onChange: handleCurrentPasswordChange, required: true, style: {
                            width: "100%",
                            padding: "3px",
                            marginTop: "5px",
                            borderColor: errors.currentPassword ? "red" : ""
                        } }),
                    React.createElement(Button, { onClick: function () { return setShowCurrentPassword(function (prev) { return !prev; }); }, style: { marginLeft: "10px" } }, showCurrentPassword ? "Skrýt" : "Zobrazit")),
                errors.currentPassword && (React.createElement("div", { style: { color: "red", marginTop: "5px" } }, errors.currentPassword))),
            React.createElement(Col, { xs: 12, style: { marginBottom: "20px" } },
                React.createElement("strong", null, "Nov\u00E9 Heslo"),
                React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                    React.createElement("input", { type: showNewPassword ? "text" : "password", value: newPassword, onChange: handleNewPasswordChange, required: true, style: {
                            width: "100%",
                            padding: "3px",
                            marginTop: "5px",
                            borderColor: errors.newPassword ? "red" : ""
                        } }),
                    React.createElement(Button, { onClick: function () { return setShowNewPassword(function (prev) { return !prev; }); }, style: { marginLeft: "10px" } }, showNewPassword ? "Skrýt" : "Zobrazit")),
                errors.newPassword && (React.createElement("div", { style: { color: "red", marginTop: "5px" } }, errors.newPassword)))))); };
    var renderErrorModal = function () { return (React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
        React.createElement("div", null, errorMessage),
        React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt"))); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", disabled: shouldChangePassword, onClick: function () { return history.goBack(); }, fullWidth: true }, "Zp\u011Bt") }));
    if (!shouldChangePassword) {
        return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            renderErrorModal(),
            React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Zm\u011Bnit Heslo", footer: footer }, renderForm())));
    }
    else {
        return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            renderErrorModal(),
            React.createElement(Modal, { open: true, label: "Zm\u011Bnit Heslo", footer: React.createElement(React.Fragment, null,
                    React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit }, "Ulo\u017Eit")) }, renderForm())));
    }
}
export default MyProfilePassword;
