var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from "react";
import { AccordionSet, Accordion } from "@folio/stripes/components";
var CustomAccordionSet = function (_a) {
    var accordions = _a.accordions, _b = _a.expandAll, expandAll = _b === void 0 ? true : _b, onToggleExpandAll = _a.onToggleExpandAll;
    var _c = useState(accordions.reduce(function (acc, accordion) {
        acc[accordion.id] = expandAll;
        return acc;
    }, {})), accordionStatus = _c[0], setAccordionStatus = _c[1];
    useEffect(function () {
        setAccordionStatus(accordions.reduce(function (acc, accordion) {
            acc[accordion.id] = expandAll;
            return acc;
        }, {}));
    }, [expandAll, accordions]);
    var toggleAccordion = function (id) {
        setAccordionStatus(function (prevState) {
            var _a;
            var newStatus = __assign(__assign({}, prevState), (_a = {}, _a[id] = !prevState[id], _a));
            var allCollapsed = Object.values(newStatus).every(function (status) { return !status; });
            var allExpanded = Object.values(newStatus).every(function (status) { return status; });
            if (onToggleExpandAll && allCollapsed) {
                onToggleExpandAll(!allCollapsed);
            }
            if (onToggleExpandAll && allExpanded) {
                onToggleExpandAll(allExpanded);
            }
            return newStatus;
        });
    };
    return (React.createElement(AccordionSet, null, accordions.map(function (accordion) { return (React.createElement(Accordion, { key: accordion.id, id: accordion.id, label: accordion.label, open: accordionStatus[accordion.id], onToggle: function () { return toggleAccordion(accordion.id); } }, accordion.content)); })));
};
export default CustomAccordionSet;
