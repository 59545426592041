import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, MenuSection, Pane } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import AresUpdatesDetail from "./AresUpdatesDetail";
import { fetchAresUpdates, fetchAresUpdatesData } from "../../api/aresService";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var PAGE_SIZE = 20;
var AresUpdatesList = function () {
    var _a;
    var history = useHistory();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var _b = useState(null), selectedAresUpdate = _b[0], setSelectedAresUpdate = _b[1];
    var _c = useState(true), filterPaneIsVisible = _c[0], setFilterPaneIsVisible = _c[1];
    var _d = useState(0), totalRecords = _d[0], setTotalRecords = _d[1];
    var _e = useState(0), pageOffset = _e[0], setPageOffset = _e[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var aresId = queryParams.get("detail");
        if (aresId) {
            fetchAresUpdatesData(aresId, ky).then(function (ares) {
                if (ares) {
                    setSelectedAresUpdate(ares);
                    // Ensure filters are preserved when opening detail
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var _f = useQuery(["ares-update-requests", pageOffset], function () {
        return fetchAresUpdates(ky, {
            offset: pageOffset,
            limit: PAGE_SIZE,
        });
    }, {
        enabled: true,
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _f.data, isLoading = _f.isLoading, isError = _f.isError, refetch = _f.refetch;
    var aresUpdatesData = (_a = data === null || data === void 0 ? void 0 : data.aresUpdateRequests) !== null && _a !== void 0 ? _a : [];
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var formatDateToEuropean = function (dateString) {
        var date = new Date(dateString);
        var day = String(date.getDate()).padStart(2, "0");
        var month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        var year = date.getFullYear();
        return "".concat(day, ".").concat(month, ".").concat(year);
    };
    // Define columns using keyof AresUpdateRequest
    var columns = useMemo(function () { return [
        {
            name: "Nakladatel",
            key: "publisher", // This is the key for the publisher object
            cellRenderer: function (item) { var _a; return ((_a = item.publisher) === null || _a === void 0 ? void 0 : _a.name) || "N/A"; }, // Render the publisher name
            clickable: true,
            onClick: function (item) {
                // Append filters to the URL when navigating to detail
                var currentParams = new URLSearchParams(location.search);
                // Remove any existing 'detail' query parameter
                currentParams.delete("detail");
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedAresUpdate(item);
            },
        },
        {
            name: "Čas vytvoření",
            key: "createTime", // This is a key in AresUpdateRequest
            clickable: true,
            cellRenderer: function (item) {
                return item.createTime ? formatDateToEuropean(item.createTime) : "";
            },
        },
    ]; }, []);
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return aresUpdatesData; };
    source.resultCount = function () { return totalRecords; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return true; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.successfulMutations = function () { return []; };
    source.fetchMore = function () { return fetchMoreData; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace" },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: "Seznam ARES aktualizac\u00ED" },
                React.createElement(TableComponent, { data: aresUpdatesData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, filterPaneIsVisible: filterPaneIsVisible, onNeedMoreData: function (offset) {
                        fetchMoreData(offset);
                    }, toggleFilterPane: function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); }, source: source })),
            selectedAresUpdate && (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail nakladatele", onClose: function () {
                    setSelectedAresUpdate(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() });
                }, dismissible: true, actionMenu: function () { return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" }, hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                        return history.push("/brpublishers/ares-updates/edit/".concat(selectedAresUpdate.id));
                    }, fullWidth: true }, "Zm\u011Bnit")))); } }, selectedAresUpdate.publisher ? (React.createElement(AresUpdatesDetail, { ares: selectedAresUpdate })) : (React.createElement("div", null, "No details available")))))));
};
export default AresUpdatesList;
