import React from "react";
import { Row, Col } from "@folio/stripes/components";
var RenderField = function (_a) {
    var label = _a.label, value = _a.value, translations = _a.translations;
    var displayValue = value;
    if (label === "Typ" && typeof value === "string" && translations) {
        displayValue = translations[value] || value;
    }
    return (React.createElement(Row, { style: { marginBottom: "25px" } },
        React.createElement(Col, { xs: 12 },
            React.createElement("div", { style: { marginBottom: "5px" } },
                React.createElement("strong", { style: { fontSize: "1.1em", paddingRight: "5px" } }, label)),
            React.createElement("div", null, displayValue || "N/A"))));
};
export default RenderField;
