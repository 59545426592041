var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { Pane, Select, Button, Row, Col, TextArea, Datepicker, AccordionSet, Accordion, Checkbox, Selection, PaneFooter, ErrorModal, } from "@folio/stripes/components";
import { useHistory } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import debounce from "lodash/debounce";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import publisherValidationSchema from "../../utils/publisherValidationUtils";
import { createPublisher, fetchAddress, fetchAddressCode, fetchRegions, lookupPublisherByCompanyNumber, } from "../../api/publisherService";
import RecekTextField from "../../components/RecekTextField";
var PublisherCreate = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var history = useHistory();
    var ky = useOkapiKy();
    var _r = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
    }), accordionStatus = _r[0], setAccordionStatus = _r[1];
    var _s = useForm({
        resolver: zodResolver(publisherValidationSchema),
        defaultValues: {
            companyNumber: "",
            name: "",
            nameSuffix: "",
            qualifier: "",
            legalForm: "LEGAL",
            taxNumber: "",
            birthDate: "",
            mainAddress: {
                addressCode: "",
                street: "",
                postalCode: "",
                municipality: "",
                region: { id: "", name: "" },
            },
            mailingAddress: {
                addressCode: "",
                street: "",
                postalCode: "",
                municipality: "",
                region: { id: "", name: "" },
            },
            contacts: [],
            alternativeNames: [],
            internalComment: "",
        },
        mode: "onChange",
    }), handleSubmit = _s.handleSubmit, register = _s.register, control = _s.control, setValue = _s.setValue, getValues = _s.getValues, errors = _s.formState.errors;
    var _t = useState(false), enableMainAddress = _t[0], setEnableMainAddress = _t[1];
    var _u = useState(false), enableMailingAddress = _u[0], setEnableMailingAddress = _u[1];
    var _v = useState(""), errorMessage = _v[0], setErrorMessage = _v[1];
    var _w = useState(false), errorModalOpen = _w[0], setErrorModalOpen = _w[1];
    var _x = useState(false), showMailingAddress = _x[0], setShowMailingAddress = _x[1];
    var _y = useState(true), expandAll = _y[0], setExpandAll = _y[1];
    var _z = useState([]), regions = _z[0], setRegions = _z[1]; // State for regions
    var _0 = useState(""), addressQuery = _0[0], setAddressQuery = _0[1]; // Holds the current input text for the query
    var _1 = useState([]), addressData = _1[0], setAddressData = _1[1]; // State for address data
    var _2 = useState(false), addressLoading = _2[0], setAddressLoading = _2[1]; // Loading state for addresses
    var _3 = useState(""), mailingAddressQuery = _3[0], setMailingAddressQuery = _3[1]; // For the mailing address query
    var _4 = useState([]), mailingAddressData = _4[0], setMailingAddressData = _4[1]; // Holds mailing addresses
    // Debounced function to delay the query call as the user types
    var debouncedSetAddressQuery = useCallback(debounce(function (inputValue) {
        setAddressQuery(inputValue);
    }, 300), []);
    // Handle input changes in the Selection component
    var handleAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddressLoading(true);
                    return [4 /*yield*/, debouncedSetAddressQuery(inputValue)];
                case 1:
                    _a.sent();
                    setAddressLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAddressSelection = function (selectedValue) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails = _a.sent();
                    setValue("mainAddress.addressCode", selectedValue);
                    setValue("mainAddress.street", addressDetails.street);
                    setValue("mainAddress.postalCode", addressDetails.postalCode);
                    setValue("mainAddress.municipality", addressDetails.municipality);
                    setValue("mainAddress.region", addressDetails.region || { id: "", name: "" });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error fetching address details:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchAddress(ky, addressQuery)
            .then(function (publisherAddresses) {
            setAddressData(publisherAddresses);
        })
            .catch(function (error) {
            console.error("Error fetching addresses:", error);
            setAddressData([]); // Clear addresses in case of an error
        });
    }, [addressQuery]);
    useEffect(function () {
        fetchAddress(ky, mailingAddressQuery)
            .then(function (addresses) {
            setMailingAddressData(addresses);
        })
            .catch(function (error) {
            console.error("Error fetching mailing addresses:", error);
            setMailingAddressData([]); // Clear addresses in case of an error
        });
    }, [mailingAddressQuery]);
    useQuery("regions", function () { return fetchRegions(ky); }, {
        onSuccess: function (data) {
            console.log("Fetched regions:", data);
            setRegions(data); // Set the regions state
        },
    });
    var handleInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        // Ensure name is a valid form key
        if (name in getValues()) {
            setValue(name, value, { shouldValidate: true });
        }
    };
    var handleAddressChange = function (e, type) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name === "region") {
            var selectedRegion = regions.find(function (region) { return region.id === value; });
            setValue("".concat(type, ".region.id"), (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.id) || "", { shouldValidate: true });
            setValue("".concat(type, ".region.name"), (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.name) || "", { shouldValidate: true });
        }
        else {
            setValue("".concat(type, ".").concat(name), value, { shouldValidate: true });
        }
    };
    var addContact = function () {
        var contacts = __spreadArray([], (getValues("contacts") || []), true);
        contacts.push({ type: "EMAIL", value: "" });
        setValue("contacts", contacts, { shouldValidate: true });
    };
    var removeContact = function (index) {
        var contacts = __spreadArray([], (getValues("contacts") || []), true);
        contacts.splice(index, 1);
        setValue("contacts", contacts, { shouldValidate: true });
    };
    var addAlternativeName = function () {
        var alternativeNames = __spreadArray([], (getValues("alternativeNames") || []), true);
        alternativeNames.push({ name: "", nonPublic: false });
        setValue("alternativeNames", alternativeNames, { shouldValidate: true });
    };
    var removeAlternativeName = function (index) {
        var alternativeNames = __spreadArray([], (getValues("alternativeNames") || []), true);
        alternativeNames.splice(index, 1);
        setValue("alternativeNames", alternativeNames, { shouldValidate: true });
    };
    var handleIco = function () { return __awaiter(void 0, void 0, void 0, function () {
        var companyNumber, publisher, error_2;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 4, , 5]);
                    companyNumber = getValues("companyNumber");
                    if (!companyNumber || companyNumber.trim() === "") {
                        setErrorMessage("IČO je povinné.");
                        setErrorModalOpen(true);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, lookupPublisherByCompanyNumber(ky, companyNumber)];
                case 1:
                    publisher = _f.sent();
                    setValue("name", publisher.name, { shouldValidate: true });
                    setValue("legalForm", (_a = publisher.legalForm) !== null && _a !== void 0 ? _a : getValues("legalForm"), { shouldValidate: true });
                    setValue("taxNumber", (_b = publisher.taxNumber) !== null && _b !== void 0 ? _b : getValues("taxNumber"), { shouldValidate: true });
                    setValue("contacts", ((_d = (_c = publisher.contacts) !== null && _c !== void 0 ? _c : getValues("contacts")) !== null && _d !== void 0 ? _d : []), {
                        shouldValidate: true,
                    });
                    if (!((_e = publisher.mainAddress) === null || _e === void 0 ? void 0 : _e.addressCode)) return [3 /*break*/, 3];
                    return [4 /*yield*/, handleAddressSelection(publisher.mainAddress.addressCode)];
                case 2:
                    _f.sent();
                    _f.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_2 = _f.sent();
                    console.error("Error fetching Ares data:", error_2);
                    setErrorMessage("Došlo k chybě při hledáni IČA. Chyba ze serveru: " + error_2);
                    setErrorModalOpen(true);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var expandAllSections = function () {
        setAccordionStatus({
            general: true,
            address: true,
            contacts: true,
            alternativeNames: true,
            comments: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            general: false,
            address: false,
            contacts: false,
            alternativeNames: false,
            comments: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createPublisher(ky, data)];
                case 1:
                    _a.sent();
                    history.goBack();
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    setErrorMessage("Do\u0161lo k chyb\u011B p\u0159i vytv\u00E1\u0159en\u00ED nakladatele: ".concat(error_3));
                    setErrorModalOpen(true);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit(onSubmit) }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); } }, "Zru\u0161it") }));
    return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Zalo\u017Een\u00ED nakladatele", footer: footer },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6, style: { display: "flex", alignItems: "flex-end" } },
                                React.createElement(RecekTextField, __assign({ label: "I\u010CO", required: true }, register("companyNumber"), { error: (_a = errors.companyNumber) === null || _a === void 0 ? void 0 : _a.message, onChange: handleInputChange })),
                                React.createElement(Button, { buttonStyle: "primary", onClick: handleIco, style: {
                                        marginLeft: "16px",
                                        marginBottom: errors.companyNumber ? "2.6rem" : "1rem",
                                    } }, "Na\u010D\u00EDst dle I\u010CO z rejst\u0159\u00EDku")),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, __assign({ label: "N\u00E1zev" }, register("name"), { onChange: handleInputChange, required: true, error: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message })))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, __assign({ label: "Dodatek" }, register("nameSuffix"), { onChange: handleInputChange }))),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, __assign({ label: "Forma" }, register("legalForm"), { onChange: handleInputChange }),
                                    React.createElement("option", { value: "LEGAL" }, "Pr\u00E1vnick\u00E1 osoba"),
                                    React.createElement("option", { value: "NATURAL" }, "Fyzick\u00E1 osoba")))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, __assign({ label: "\u010C\u00EDslo DI\u010C", required: true, error: (_c = errors.taxNumber) === null || _c === void 0 ? void 0 : _c.message }, register("taxNumber"), { onChange: handleInputChange }))),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "birthDate", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(Datepicker, __assign({ label: "Datum narozen\u00ED" }, field, { error: (_b = errors.birthDate) === null || _b === void 0 ? void 0 : _b.message, disabled: getValues("legalForm") === "LEGAL", backendDateStandard: "YYYY-MM-DD", dateFormat: "DD.MM.YYYY", placeholder: "DD.MM.RRRR" })));
                                    } })))),
                    React.createElement(Accordion, { label: "Adresa", open: accordionStatus.address, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.addressCode", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Selection, __assign({}, field, { label: "Adresa", disabled: addressLoading, asyncFilter: true, onFilter: handleAddressInput, dataOptions: addressData.map(function (address) { return ({
                                                value: address.addressCode,
                                                label: address.address,
                                            }); }) })));
                                    } })),
                            React.createElement(Col, { xs: 6, style: { display: "flex", alignItems: "center" } },
                                React.createElement(Checkbox, { label: "Povolit zad\u00E1n\u00ED hlavn\u00ED adresy", checked: enableMainAddress, onChange: function () {
                                        setEnableMainAddress(!enableMainAddress);
                                        if (!enableMainAddress) {
                                            setValue("mainAddress.addressCode", "");
                                            setValue("mainAddress.street", "");
                                            setValue("mainAddress.postalCode", "");
                                            setValue("mainAddress.municipality", "");
                                            setValue("mainAddress.region", { id: "", name: "" });
                                        }
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.region.id", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: "Kraj", required: true, error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMainAddress, dataOptions: __spreadArray([
                                                { value: "", label: "--- Vyberte kraj ---" }
                                            ], regions.map(function (region) { return ({
                                                value: region.id,
                                                label: region.name,
                                            }); }), true), onChange: function (e) {
                                                var selectedRegion = regions.find(function (region) { return region.id === e.target.value; });
                                                setValue("mainAddress.region", selectedRegion || { id: "", name: "" });
                                            } })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, __assign({ label: "Ulice" }, register("mainAddress.street"), { error: (_e = (_d = errors.mainAddress) === null || _d === void 0 ? void 0 : _d.street) === null || _e === void 0 ? void 0 : _e.message, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, disabled: !enableMainAddress, required: true }))),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, __assign({ label: "PS\u010C" }, register("mainAddress.postalCode"), { error: (_g = (_f = errors.mainAddress) === null || _f === void 0 ? void 0 : _f.postalCode) === null || _g === void 0 ? void 0 : _g.message, disabled: !enableMainAddress, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, required: true }))),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, __assign({ label: "Obec" }, register("mainAddress.municipality"), { error: (_j = (_h = errors.mainAddress) === null || _h === void 0 ? void 0 : _h.municipality) === null || _j === void 0 ? void 0 : _j.message, disabled: !enableMainAddress, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, required: true })))),
                        React.createElement(Checkbox, { label: "Li\u0161\u00ED se doru\u010Dovac\u00ED adresa?", checked: showMailingAddress, onChange: function () { return setShowMailingAddress(!showMailingAddress); } }),
                        showMailingAddress && (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.addressCode", render: function (_a) {
                                            var field = _a.field;
                                            return (React.createElement(Selection, __assign({}, field, { label: "Adresa", disabled: addressLoading, asyncFilter: true, onFilter: handleAddressInput, dataOptions: addressData.map(function (address) { return ({
                                                    value: address.addressCode,
                                                    label: address.address,
                                                }); }) })));
                                        } })),
                                React.createElement(Col, { xs: 6, style: { display: "flex", alignItems: "center" } },
                                    React.createElement(Checkbox, { label: "Povolit zad\u00E1n\u00ED doru\u010Dovac\u00ED adresy ru\u010Dne", checked: enableMailingAddress, onChange: function () {
                                            setEnableMailingAddress(!enableMailingAddress);
                                            if (!enableMailingAddress) {
                                                setValue("mailingAddress.addressCode", "");
                                                setValue("mailingAddress.street", "");
                                                setValue("mailingAddress.postalCode", "");
                                                setValue("mailingAddress.municipality", "");
                                                setValue("mailingAddress.region", { id: "", name: "" });
                                            }
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.region.id", render: function (_a) {
                                            var _b, _c;
                                            var field = _a.field;
                                            return (React.createElement(Select, __assign({}, field, { label: "Kraj", required: true, error: (_c = (_b = errors.mailingAddress) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMailingAddress, dataOptions: __spreadArray([
                                                    { value: "", label: "--- Vyberte kraj ---" }
                                                ], regions.map(function (region) { return ({
                                                    value: region.id,
                                                    label: region.name,
                                                }); }), true), onChange: function (e) {
                                                    var selectedRegion = regions.find(function (region) { return region.id === e.target.value; });
                                                    setValue("mailingAddress.region", selectedRegion || { id: "", name: "" });
                                                } })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, __assign({ label: "Ulice" }, register("mailingAddress.street"), { required: true, disabled: !enableMailingAddress, error: (_l = (_k = errors.mailingAddress) === null || _k === void 0 ? void 0 : _k.street) === null || _l === void 0 ? void 0 : _l.message, onChange: function (e) {
                                            return handleAddressChange(e, "mailingAddress");
                                        } }))),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, __assign({ label: "PS\u010C" }, register("mailingAddress.postalCode"), { error: (_o = (_m = errors.mailingAddress) === null || _m === void 0 ? void 0 : _m.postalCode) === null || _o === void 0 ? void 0 : _o.message, required: true, disabled: !enableMailingAddress, onChange: function (e) {
                                            return handleAddressChange(e, "mailingAddress");
                                        } }))),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, __assign({ label: "Obec" }, register("mailingAddress.municipality"), { error: (_q = (_p = errors.mailingAddress) === null || _p === void 0 ? void 0 : _p.municipality) === null || _q === void 0 ? void 0 : _q.message, required: true, disabled: !enableMailingAddress, onChange: function (e) {
                                            return handleAddressChange(e, "mailingAddress");
                                        } }))))))),
                    React.createElement(Accordion, { label: "Kontakty", open: accordionStatus.contacts, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { contacts: !accordionStatus.contacts }));
                        } },
                        getValues("contacts").map(function (contact, index) { return (React.createElement(Row, { key: index },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "contacts.".concat(index, ".type"), render: function (_a) {
                                        var _b, _c, _d, _e, _f;
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: "Typ kontaktu", required: true, error: typeof ((_c = (_b = errors.contacts) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.type) === "object" ? (_f = (_e = (_d = errors.contacts) === null || _d === void 0 ? void 0 : _d[index]) === null || _e === void 0 ? void 0 : _e.type) === null || _f === void 0 ? void 0 : _f.message : undefined, dataOptions: [
                                                { value: "EMAIL", label: "E-mail" },
                                                { value: "WEB", label: "Web" },
                                                { value: "PHONE", label: "Telefon" },
                                                { value: "DATA_BOX", label: "Datová schránka" },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "contacts.".concat(index, ".value"), render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Kontakt", required: true, error: (_d = (_c = (_b = errors.contacts) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeContact(index); }, style: { marginTop: "25px" } }, "Odstranit")))); }),
                        React.createElement(Button, { onClick: addContact }, "P\u0159idat kontakt")),
                    React.createElement(Accordion, { label: "Alternativn\u00ED n\u00E1zvy", open: accordionStatus.alternativeNames, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { alternativeNames: !accordionStatus.alternativeNames }));
                        } },
                        getValues("alternativeNames").map(function (alternativeName, index) { return (React.createElement(Row, { key: index },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".name"), render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Alternativn\u00ED n\u00E1zev", required: true, error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".nameSuffix"), render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "P\u0159\u00EDpona n\u00E1zvu", error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.nameSuffix) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".qualifier"), render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Kvalifik\u00E1tor", error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.qualifier) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".type"), render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: "Typ", dataOptions: [
                                                { value: "PREVIOUS", label: "Předchozí název" },
                                                { value: "IMPRINT", label: "Imprint" },
                                                { value: "ABBREVATION", label: "Zkratka" },
                                                { value: "ORGANIZATIONAL_UNIT", label: "Organizační jednotka" },
                                                { value: "FOREIGN_LANGUAGE", label: "Cizojazyčný ekvivalent" },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".nonPublic"), render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Checkbox, { checked: field.value, onChange: function (e) { return field.onChange(e.target.checked); }, label: "Neve\u0159ejn\u00E9" }));
                                    } })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeName(index); }, style: { marginTop: "10px" } }, "Odstranit alternativn\u00ED n\u00E1zev")))); }),
                        React.createElement(Button, { onClick: addAlternativeName }, "P\u0159idat alternativn\u00ED n\u00E1zev")),
                    React.createElement(Accordion, { label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { comments: !accordionStatus.comments }));
                        } },
                        React.createElement(TextArea, __assign({ label: "Pozn\u00E1mka" }, register("internalComment"), { onChange: handleInputChange }))))))));
};
export default PublisherCreate;
