import { z } from "zod";
var formSchema = z
    .object({
    legalForm: z.enum(["LEGAL", "NATURAL"], {
        required_error: "Forma je povinná.",
    }),
    companyNumber: z
        .string()
        .min(8, { message: "IČO musí mít přesně 8 číslic." })
        .max(8, { message: "IČO musí mít přesně 8 číslic." })
        .optional(),
    name: z.string().min(1, { message: "Název je povinný." }),
    taxNumber: z
        .string()
        .optional()
        .refine(function (val) { return !val || /^[A-Za-z]{2}\d{2,13}$/.test(val); }, {
        message: "DIČ musí mít 2 písmena následovaná 2 až 13 číslicemi.",
    }),
    birthDate: z
        .string()
        .optional()
        .refine(function (val) { return !val || /^\d{2}\.\d{2}\.\d{4}$/.test(val); }, {
        message: "Datum narození musí být ve formátu DD.MM.RRRR.",
    }),
    mainAddress: z.object({
        street: z.string().min(1, { message: "Ulice je povinná." }),
        postalCode: z
            .string()
            .regex(/^\d{3} ?\d{2}$/, { message: "PSČ musí mít 5 číslic." }),
        municipality: z.string().min(1, { message: "Obec je povinná." }),
        region: z.object({
            id: z.string().min(1, { message: "Kraj je povinný." }),
            name: z.string(),
        }),
    }),
    mailingAddress: z
        .object({
        street: z.string().min(1, { message: "Ulice doručení je povinná." }),
        postalCode: z
            .string()
            .regex(/^\d{3} ?\d{2}$/, { message: "PSČ musí mít 5 číslic." }),
        municipality: z.string().min(1, { message: "Obec doručení je povinná." }),
        region: z.object({
            id: z.string().min(1, { message: "Kraj doručení je povinný." }),
            name: z.string(),
        }),
    })
        .optional(),
    contacts: z
        .array(z.object({
        type: z.enum(["EMAIL", "WEB", "PHONE", "DATA_BOX"]),
        value: z
            .string()
            .min(1, { message: "Kontakt je povinný." })
            .superRefine(function (val, ctx) {
            if (val.length < 7 || val.length > 15) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Hodnota kontaktu typu Telefon má méně než 9 číslic.",
                });
            }
        }),
    }))
        .optional(),
    alternativeNames: z
        .array(z.object({
        name: z.string().min(1, { message: "Alternativní název je povinný." }),
        nameSuffix: z
            .string()
            .max(50, { message: "Přípona názvu nesmí překročit 50 znaků." })
            .optional(),
        qualifier: z
            .string()
            .max(50, { message: "Kvalifikátor nesmí překročit 50 znaků." })
            .optional(),
        type: z
            .enum([
            "previous",
            "imprint",
            "abbreviation",
            "organizationalUnit",
            "foreignLanguage",
        ])
            .optional(),
        nonPublic: z.boolean(),
    }))
        .optional(),
})
    .superRefine(function (data, ctx) {
    var _a;
    if (data.legalForm === "NATURAL") {
        if (!data.birthDate && !data.companyNumber && !data.taxNumber) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Fyzická osoba potřebuje alespoň Datum narození, IČO, nebo DIČ.",
                path: ["birthDate"],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Fyzická osoba potřebuje alespoň Datum narození, IČO, nebo DIČ.",
                path: ["companyNumber"],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Fyzická osoba potřebuje alespoň Datum narození, IČO, nebo DIČ.",
                path: ["taxNumber"],
            });
        }
    }
    if (data.legalForm === "LEGAL") {
        if (!data.companyNumber && !data.taxNumber) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Právnická osoba potřebuje IČO nebo DIČ.",
                path: ["companyNumber"],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Právnická osoba potřebuje IČO nebo DIČ.",
                path: ["taxNumber"],
            });
        }
    }
    // Contact validations
    (_a = data.contacts) === null || _a === void 0 ? void 0 : _a.forEach(function (contact, index) {
        if (!contact.value) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Kontakt je povinný.",
                path: ["contacts", index, "value"],
            });
        }
        if (contact.type === "EMAIL" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.value)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Hodnota kontaktu typu E-mail není platná e-mailová adresa.",
                path: ["contacts", index, "value"],
            });
        }
        if (contact.type === "WEB" && !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(contact.value)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Hodnota kontaktu typu Web není platná URL adresa.",
                path: ["contacts", index, "value"],
            });
        }
        if (contact.type === "PHONE" && !/^\+?[0-9\s-]{7,15}$/.test(contact.value)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Telefonní číslo musí mít 7 až 15 číslic.",
                path: ["contacts", index, "value"],
            });
        }
    });
});
export default formSchema;
