var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, IconButton, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import { fetchPublisherData, searchPublishers } from "../../api/publisherService";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import PublisherDetail from "../publishers/PublisherDetail";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var PAGE_SIZE = 20;
var PublisherRegistrationsList = function () {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var _b = useState(null), selectedPublisherRegister = _b[0], setSelectedPublisherRegister = _b[1];
    var filters = {
        name: "",
        ico: "",
        startDate: undefined,
        endDate: undefined,
        status: ["REQUEST"],
    };
    var _c = useState(true), filterPaneIsVisible = _c[0], setFilterPaneIsVisible = _c[1];
    var _d = useState(0), pageOffset = _d[0], setPageOffset = _d[1];
    var _e = useState(0), totalRecords = _e[0], setTotalRecords = _e[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var publisherId = queryParams.get("detail");
        if (publisherId) {
            fetchPublisherData(publisherId, ky).then(function (publisher) {
                if (publisher) {
                    setSelectedPublisherRegister(publisher);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var isAnyFilterActive = function () {
        return (!!filters.name ||
            !!filters.ico ||
            !!filters.startDate ||
            !!filters.endDate ||
            filters.status.length > 0);
    };
    var _f = useQuery(["publishers", filters, pageOffset], function () {
        return searchPublishers(ky, __assign(__assign({}, filters), { offset: pageOffset, limit: PAGE_SIZE }));
    }, {
        enabled: isAnyFilterActive(),
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _f.data, isLoading = _f.isLoading, isError = _f.isError, refetch = _f.refetch;
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var publisherRegistrationsData = (_a = data === null || data === void 0 ? void 0 : data.publishers) !== null && _a !== void 0 ? _a : [];
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var statusTranslations = {
        REQUEST: "Žádost",
        REJECTED: "Odmítnuto",
        ACTIVE: "Aktivní",
        CLOSING: "Uzavírání",
        CLOSED: "Uzavřeno",
    };
    var formatDateToEuropean = function (dateString) {
        var date = new Date(dateString);
        var day = String(date.getDate()).padStart(2, "0");
        var month = String(date.getMonth() + 1).padStart(2, "0");
        var year = date.getFullYear();
        return "".concat(day, ".").concat(month, ".").concat(year);
    };
    var columns = [
        {
            name: "Nakladatel",
            key: "name",
            clickable: true,
            onClick: function (item) {
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete("detail");
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedPublisherRegister(item);
            },
        },
        {
            name: "Doplňek k názvu",
            key: "nameSuffix",
        },
        {
            name: "Kvalifikátor",
            key: "qualifier",
        },
        {
            name: "IČO",
            key: "companyNumber",
        },
        {
            name: "Datum narození",
            key: "birthDate",
            cellRenderer: function (item) {
                return item.birthDate ? formatDateToEuropean(item.birthDate) : "N/A";
            },
        },
        {
            name: "Stav",
            key: "status",
            formatter: function (item) { return statusTranslations[item.status]; },
        },
    ];
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return publisherRegistrationsData; };
    source.resultCount = function () { return publisherRegistrationsData.length; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = fetchMoreData;
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: "Seznam registrac\u00ED nakladatele", paneSub: totalRecords > 0
                    ? "Nalezeno ".concat(totalRecords, " z\u00E1znam\u016F")
                    : "Zadejte vyhledávací kritéria pro zahájení vyhledávání", firstMenu: !filterPaneIsVisible && (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": "Show filters", style: { marginLeft: "auto" } })) },
                React.createElement(TableComponent, { data: publisherRegistrationsData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, onNeedMoreData: function (offset) {
                        fetchMoreData(offset);
                    }, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedPublisherRegister && (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail Registrace nakladatele", onClose: function () {
                    setSelectedPublisherRegister(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() });
                }, dismissible: true, actionMenu: function () { return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return history.push("/brpublishers/publisher-registrations/approve/".concat(selectedPublisherRegister.id));
                        }, fullWidth: true }, "Schv\u00E1lit")),
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return history.push("/brpublishers/publisher-registrations/reject/".concat(selectedPublisherRegister.id));
                        }, fullWidth: true }, "Zam\u00EDtnout")),
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return history.push("/brpublishers/publisher-registrations/restore/".concat(selectedPublisherRegister.id));
                        }, fullWidth: true }, "Obnovit")))); } }, selectedPublisherRegister ? (React.createElement(PublisherDetail, { publisher: selectedPublisherRegister })) : (React.createElement("div", null, "No details available")))))));
};
export default PublisherRegistrationsList;
