import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { routesConfig } from "./routesConfig";
import Settings from "./settings";

class BrAgency extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    showSettings: PropTypes.bool,
    stripes: PropTypes.shape({
      connect: PropTypes.func,
    }),
  };

  constructor(props) {
    super(props);

    this.connectedRoutes = Object.keys(routesConfig).reduce((acc, key) => {
      acc[key] = props.stripes.connect(routesConfig[key].component);
      return acc;
    }, {});
  }

  render() {
    const {
      showSettings,
      match: { path },
    } = this.props;

    if (showSettings) {
      return <Settings {...this.props} />;
    }

    return (
      <Switch>
        {Object.keys(routesConfig).map((key) => (
          <Route
            key={key}
            path={`${path}${routesConfig[key].path}`}
            component={this.connectedRoutes[key]}
          />
        ))}
        {/* Default Redirect */}
        <Route render={() => <Redirect to={`${path}${routesConfig.agencyPrefixes.path}`} />} />
      </Switch>
    );
  }
}

export default BrAgency;