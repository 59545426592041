var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useCallback } from "react";
import { Pane, Button, Row, Col, Select, AccordionSet, Accordion, TextArea, Checkbox, Selection, ErrorModal, PaneFooter, } from "@folio/stripes/components";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { useOkapiKy } from "@folio/stripes/core";
import { debounce } from "lodash";
import { fetchAddress, fetchAddressCode, fetchRegions, } from "../../api/publisherService";
import { fetchAresUpdatesData } from "../../api/aresService";
import RecekTextField from "../../components/RecekTextField";
var AresUpdateEdit = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var _l = useState({
        id: "", // Default value for 'id'
        version: 0,
        companyNumber: "",
        name: "",
        nameSuffix: "",
        qualifier: "",
        legalForm: "LEGAL",
        taxNumber: "",
        birthDate: "",
        mainAddress: {
            addressCode: "",
            street: "",
            postalCode: "",
            municipality: "",
            region: { id: "", name: "" }, // New field
        },
        mailingAddress: {
            addressCode: "",
            street: "",
            postalCode: "",
            municipality: "",
            region: { id: "", name: "" }, // New field
        },
        contacts: [],
        alternativeNames: [],
        internalComment: "",
        origin: "PUBLISHER", // Default value for 'origin'
        status: "ACTIVE", // Default value for 'status'
        createTime: new Date().toISOString(), // Default value for 'createTime'
        dataConfirmationTime: new Date().toISOString(), // Default value for 'dataConfirmationTime'
    }), formValues = _l[0], setFormValues = _l[1];
    var _m = useState({
        companyNumber: "",
        name: "",
        taxNumber: "",
        addressCode: "",
    }), aresFormValues = _m[0], setAresFormValues = _m[1];
    var _o = useState(false), registration = _o[0], setRegistration = _o[1];
    var _p = useState({}), errors = _p[0], setErrors = _p[1];
    var _q = useState(false), expandAll = _q[0], setExpandAll = _q[1];
    var _r = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
    }), accordionStatus = _r[0], setAccordionStatus = _r[1];
    var _s = useState(false), errorModalOpen = _s[0], setErrorModalOpen = _s[1];
    var _t = useState(""), errorMessage = _t[0], setErrorMessage = _t[1];
    var _u = useState([]), regions = _u[0], setRegions = _u[1];
    var _v = useState({
        addressCode: "",
        municipality: "",
        postalCode: "",
        region: {
            id: "",
            name: "",
        },
        street: "",
    }), aresAddress = _v[0], setAresAddress = _v[1];
    var _w = useState([]), addressData = _w[0], setAddressData = _w[1];
    var showMailingAddress = useState(false)[0];
    var _x = useState(""), selectedAddress = _x[0], setSelectedAddress = _x[1];
    var _y = useState(false), addressLoading = _y[0], setAddressLoading = _y[1];
    var _z = useState(""), addressQuery = _z[0], setAddressQuery = _z[1]; // Holds the current input text for the query
    // Fetch publisher data by ID using react-query
    var aresData = useQuery(["ares-update-requests", id], function () { return fetchAresUpdatesData(id, ky); }, { enabled: !!id }).data;
    useQuery("regions", function () { return fetchRegions(ky); }, {
        onSuccess: function (data) {
            setRegions(data); // Set the regions state
        },
    });
    useEffect(function () {
        var _a;
        if (aresData) {
            setFormValues(aresData.publisher);
            setAresFormValues(aresData.data);
            setSelectedAddress(((_a = aresData.publisher.mainAddress) === null || _a === void 0 ? void 0 : _a.addressCode) || "");
            if (aresData.publisher.status === "REQUEST") {
                setRegistration(true);
            }
        }
    }, [aresData]);
    useEffect(function () {
        var _a;
        if (aresFormValues) {
            fetchAddressCode(ky, (_a = aresFormValues.addressCode) !== null && _a !== void 0 ? _a : "")
                .then(function (publisherAddress) {
                setAresAddress(publisherAddress);
            })
                .catch(function (error) {
                console.error("Error fetching ares address:", error);
                setAddressData([]); // Clear addresses in case of an error
            });
        }
    });
    useEffect(function () {
        fetchAddress(ky, addressQuery)
            .then(function (publisherAddresses) {
            setAddressData(publisherAddresses);
        })
            .catch(function (error) {
            console.error("Error fetching addresses:", error);
            setAddressData([]); // Clear addresses in case of an error
        });
    }, [addressQuery]);
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var handleAddressChange = function (e, type) {
        var _a = e.target, name = _a.name, value = _a.value;
        setFormValues(function (prevFormValues) {
            var _a, _b;
            return (__assign(__assign({}, prevFormValues), (_a = {}, _a[type] = __assign(__assign({}, prevFormValues[type]), (_b = {}, _b[name] = value, _b)), _a)));
        });
    };
    var handleAddressSelection = function (selectedValue) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setSelectedAddress(selectedValue);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails_1 = _a.sent();
                    setFormValues(function (prevFormValues) { return (__assign(__assign({}, prevFormValues), { mainAddress: {
                            addressCode: selectedValue,
                            street: addressDetails_1.street,
                            postalCode: addressDetails_1.postalCode,
                            municipality: addressDetails_1.municipality,
                            region: addressDetails_1.region || { id: "", name: "" },
                        } })); });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error fetching address details:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var debouncedSetAddressQuery = useCallback(debounce(function (inputValue) {
        setAddressQuery(inputValue);
    }, 300), []);
    var handleAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddressLoading(true);
                    console.log(addressData.map(function (address) { return ({
                        value: address.addressCode,
                        label: address.address,
                    }); }));
                    console.log("Input value for address search:", inputValue);
                    return [4 /*yield*/, debouncedSetAddressQuery(inputValue)];
                case 1:
                    _a.sent();
                    setAddressLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAlternativeNameChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        // Ensure alternativeNames is always treated as an array
        var updatedAlternativeNames = formValues.alternativeNames
            ? __spreadArray([], formValues.alternativeNames, true) : [];
        // Check if index is within bounds
        if (updatedAlternativeNames[index]) {
            updatedAlternativeNames[index] = __assign(__assign({}, updatedAlternativeNames[index]), (_a = {}, _a[name] = value, _a));
        }
        else {
            // Optionally handle the case where the index is out of bounds
            console.warn("Index ".concat(index, " is out of bounds for alternative names."));
        }
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: updatedAlternativeNames }));
    };
    var addAlternativeName = function () {
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: __spreadArray(__spreadArray([], (formValues.alternativeNames || []), true), [
                {
                    name: "",
                    nameSuffix: "",
                    qualifier: "",
                    type: "PREVIOUS",
                    nonPublic: false,
                },
            ], false) }));
    };
    var removeAlternativeName = function (index) {
        var _a;
        var updatedAlternativeNames = (_a = formValues.alternativeNames) === null || _a === void 0 ? void 0 : _a.filter(function (_, i) { return i !== index; });
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: updatedAlternativeNames }));
    };
    var toggleExpandCollapseAll = function () {
        setExpandAll(!expandAll);
        setAccordionStatus({
            general: !expandAll,
            address: !expandAll,
            contacts: !expandAll,
            alternativeNames: !expandAll,
            comments: !expandAll,
        });
    };
    var validateForm = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var newErrors = {
            mainAddress: {},
            mailingAddress: {},
            contacts: [],
            alternativeNames: [],
        };
        // Main fields validation
        if (!formValues.name)
            newErrors.name = "Název je povinný.";
        if (!((_a = formValues.mainAddress) === null || _a === void 0 ? void 0 : _a.addressCode)) {
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { addressCode: "Adresa je povinná." });
        }
        if (!((_b = formValues.mainAddress) === null || _b === void 0 ? void 0 : _b.street)) {
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { street: "Ulice je povinná." });
        }
        if (!((_c = formValues.mainAddress) === null || _c === void 0 ? void 0 : _c.municipality)) {
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { municipality: "Obec je povinné." });
        }
        if (!((_d = formValues.mainAddress) === null || _d === void 0 ? void 0 : _d.postalCode)) {
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { postalCode: "PSČ je povinné." });
        }
        if (!((_e = formValues.mainAddress) === null || _e === void 0 ? void 0 : _e.region.id)) {
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { region: "Kraj je povinný." });
        }
        // Mailing Address validation
        if (showMailingAddress) {
            if (!((_f = formValues.mailingAddress) === null || _f === void 0 ? void 0 : _f.addressCode)) {
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { addressCode: "Doručovací adresa je povinná." });
            }
            if (!((_g = formValues.mailingAddress) === null || _g === void 0 ? void 0 : _g.street)) {
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { street: "Ulice doručení je povinná." });
            }
            if (!((_h = formValues.mailingAddress) === null || _h === void 0 ? void 0 : _h.municipality)) {
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { municipality: "Obec doručení je povinné." });
            }
            if (!((_j = formValues.mailingAddress) === null || _j === void 0 ? void 0 : _j.postalCode)) {
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { postalCode: "PSČ doručení je povinné." });
            }
            if (!((_k = formValues.mailingAddress) === null || _k === void 0 ? void 0 : _k.region.id)) {
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { region: "Kraj doručení je povinný." });
            }
        }
        // Company Number and Tax Number validation
        if (formValues.companyNumber && !/^\d{8}$/.test(formValues.companyNumber)) {
            newErrors.companyNumber = "Zadejte platné IČO.";
        }
        var taxNumber = (_l = formValues.taxNumber) !== null && _l !== void 0 ? _l : ""; // Use an empty string if taxNumber is undefined
        if ((taxNumber.length < 2 ||
            taxNumber.length > 13 ||
            !/^\d+$/.test(taxNumber)) &&
            taxNumber !== "") {
            newErrors.taxNumber = "Zadejte platné DIČ.";
        }
        // Alternative Name validation
        newErrors.alternativeNames = (formValues.alternativeNames || []).map(function (altName) {
            var altNameErrors = {};
            if (!altName.name)
                altNameErrors.name = "Alternativní název je povinný.";
            return altNameErrors;
        });
        // Set errors in state
        setErrors(newErrors);
        var hasErrors = Object.values(newErrors).some(function (error) {
            return Array.isArray(error)
                ? error.some(function (e) { return Object.keys(e).length > 0; })
                : Object.keys(error || {}).length > 0;
        });
        return !hasErrors;
    };
    var handleSubmit = function (e, status) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedFormValues;
        return __generator(this, function (_a) {
            e.preventDefault();
            updatedFormValues = __assign(__assign({}, formValues), { status: status });
            try {
                // Set form values for UI consistency (but this won’t affect updatePublisher in this call)
                setFormValues(updatedFormValues);
                // Log and use updatedFormValues directly to ensure it has the latest status
                // Navigate back after a successful submission
                history.goBack();
            }
            catch (error) {
                setErrorMessage("Došlo k chybě při editaci nakladatele. Chyba ze serveru: " + error);
                setErrorModalOpen(true);
                console.error("Error updating publisher:", error);
            }
            return [2 /*return*/];
        });
    }); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: function (e) {
                if (validateForm())
                    handleSubmit(e, "ACTIVE");
            } }, registration ? "Schválit" : "Uložit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function (e) {
                if (registration) {
                    if (validateForm())
                        handleSubmit(e, "REJECTED");
                }
                else {
                    history.goBack();
                }
            } }, registration ? "Zamítnout" : "Zrušit") }));
    return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: "\u00DAprava nakladatele", footer: footer },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
            React.createElement("form", { onSubmit: function (e) { return handleSubmit(e, "ACTIVE"); } },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "I\u010CO", name: "companyNumber", error: errors.companyNumber, value: formValues.companyNumber, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "I\u010CO z registru", name: "companyNumber", disabled: true, value: aresFormValues.companyNumber, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "N\u00E1zev", name: "name", value: formValues.name, onChange: handleInputChange, required: true, error: errors.name })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "N\u00E1zev z registru", name: "name", disabled: true, value: aresFormValues.name, onChange: handleInputChange }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "Dodatek", name: "nameSuffix", value: formValues.nameSuffix, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Forma", name: "legalForm", value: formValues.legalForm, onChange: handleInputChange },
                                    React.createElement("option", { value: "LEGAL" }, "Pr\u00E1vnick\u00E1 osoba"),
                                    React.createElement("option", { value: "PHYSICAL" }, "Fyzick\u00E1 osoba"))),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Forma z registru", name: "legalForm", disabled: true, value: aresFormValues.legalForm, onChange: handleInputChange },
                                    React.createElement("option", { value: "LEGAL" }, "Pr\u00E1vnick\u00E1 osoba"),
                                    React.createElement("option", { value: "PHYSICAL" }, "Fyzick\u00E1 osoba")))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "\u010C\u00EDslo DI\u010C", name: "taxNumber", error: errors.taxNumber, value: formValues.taxNumber, onChange: handleInputChange }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "\u010C\u00EDslo DI\u010C z registru", name: "taxNumber", disabled: true, value: aresFormValues.taxNumber, onChange: handleInputChange })))),
                    React.createElement(Accordion, { label: "Adresa S\u00EDdla", open: accordionStatus.address, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Selection, { label: "Adresa", name: "publisherAddress", error: (_a = errors.mainAddress) === null || _a === void 0 ? void 0 : _a.addressCode, value: selectedAddress, disabled: addressLoading, onChange: handleAddressSelection, asyncFilter: true, required: true, onFilter: handleAddressInput, dataOptions: __spreadArray([], addressData.map(function (address) { return ({
                                        value: address.addressCode,
                                        label: address.address,
                                    }); }), true) })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Selection, { label: "Adresa z registru", name: "publisherAddress", value: aresData === null || aresData === void 0 ? void 0 : aresData.data.addressCode, onChange: handleAddressSelection, asyncFilter: true, required: true, disabled: true, onFilter: handleAddressInput, dataOptions: __spreadArray([], addressData.map(function (address) { return ({
                                        value: address.addressCode,
                                        label: address.address,
                                    }); }), true) })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Kraj", name: "mainAddressRegion", required: true, disabled: true, value: (_b = formValues.mainAddress) === null || _b === void 0 ? void 0 : _b.region.id, error: (_c = errors.mainAddress) === null || _c === void 0 ? void 0 : _c.region, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, dataOptions: __spreadArray([
                                        { value: "", label: "--- Vyberte kraj ---" }
                                    ], regions.map(function (region) { return ({
                                        value: region.id,
                                        label: region.name,
                                    }); }), true) })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Kraj z registru", name: "mainAddressRegion", required: true, disabled: true, value: aresAddress.region.id, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, dataOptions: __spreadArray([
                                        { value: "", label: "--- Vyberte kraj ---" }
                                    ], regions.map(function (region) { return ({
                                        value: region.id,
                                        label: region.name,
                                    }); }), true) })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(RecekTextField, { label: "Ulice", name: "street", value: (_d = formValues.mainAddress) === null || _d === void 0 ? void 0 : _d.street, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, error: (_e = errors.mainAddress) === null || _e === void 0 ? void 0 : _e.street, disabled: true, required: true })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(RecekTextField, { label: "Ulice z registru", name: "street", value: aresAddress.street, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, disabled: true, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "PS\u010C", name: "postalCode", value: (_f = formValues.mainAddress) === null || _f === void 0 ? void 0 : _f.postalCode, error: (_g = errors.mainAddress) === null || _g === void 0 ? void 0 : _g.postalCode, disabled: true, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "PS\u010C z registru", name: "postalCode", value: aresAddress.postalCode, disabled: true, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "Obec", name: "municipality", value: (_h = formValues.mainAddress) === null || _h === void 0 ? void 0 : _h.municipality, error: (_j = errors.mainAddress) === null || _j === void 0 ? void 0 : _j.municipality, disabled: true, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "Obec z registru", name: "municipality", value: aresAddress.municipality, disabled: true, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, required: true })))),
                    React.createElement(Accordion, { label: "Alternativn\u00ED n\u00E1zvy", open: accordionStatus.alternativeNames, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { alternativeNames: !accordionStatus.alternativeNames }));
                        } }, (_k = formValues.alternativeNames) === null || _k === void 0 ? void 0 :
                        _k.map(function (alternativeName, index) {
                            var _a, _b, _c;
                            return (React.createElement(Row, { key: index },
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(RecekTextField, { label: "Alternativn\u00ED n\u00E1zev", name: "name", value: alternativeName.name, onChange: function (e) { return handleAlternativeNameChange(index, e); }, required: true, error: errors.alternativeNames &&
                                            ((_a = errors.alternativeNames[index]) === null || _a === void 0 ? void 0 : _a.name) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(RecekTextField, { label: "P\u0159\u00EDpona n\u00E1zvu", name: "nameSuffix", value: alternativeName.nameSuffix || "", onChange: function (e) { return handleAlternativeNameChange(index, e); }, required: true, error: errors.alternativeNames &&
                                            ((_b = errors.alternativeNames[index]) === null || _b === void 0 ? void 0 : _b.nameSuffix) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(RecekTextField, { label: "Kvalifik\u00E1tor", name: "qualifier", value: alternativeName.qualifier || "", onChange: function (e) { return handleAlternativeNameChange(index, e); }, error: errors.alternativeNames &&
                                            ((_c = errors.alternativeNames[index]) === null || _c === void 0 ? void 0 : _c.qualifier) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Select, { label: "Typ", name: "type", value: alternativeName.type || "", onChange: function (e) { return handleAlternativeNameChange(index, e); } },
                                        React.createElement("option", { value: "" }, "-"),
                                        React.createElement("option", { value: "PREVIOUS" }, "P\u0159edchoz\u00ED n\u00E1zev"),
                                        React.createElement("option", { value: "IMPRINT" }, "Imprint"),
                                        React.createElement("option", { value: "ABBREVATION" }, "Zkratka"),
                                        React.createElement("option", { value: "ORGANIZATIONAL_UNIT" }, "Organiza\u010Dn\u00ED jednotka"),
                                        React.createElement("option", { value: "FOREIGN_LANGUAGE" }, "Cizojazy\u010Dn\u00FD ekvivalent"))),
                                React.createElement(Col, { xs: 2 },
                                    React.createElement(Checkbox, { label: "Neve\u0159ejn\u00E9", checked: alternativeName.nonPublic, onChange: function () {
                                            var _a;
                                            return setFormValues(__assign(__assign({}, formValues), { alternativeNames: (_a = formValues.alternativeNames) === null || _a === void 0 ? void 0 : _a.map(function (altName, i) {
                                                    return i === index
                                                        ? __assign(__assign({}, altName), { nonPublic: !altName.nonPublic }) : altName;
                                                }) }));
                                        } })),
                                React.createElement(Col, { xs: 12 },
                                    React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeName(index); }, style: { marginTop: "10px" } }, "Odstranit alternativn\u00ED n\u00E1zev"))));
                        }),
                        React.createElement(Button, { onClick: addAlternativeName }, "P\u0159idat alternativn\u00ED n\u00E1zev")),
                    React.createElement(Accordion, { label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { comments: !accordionStatus.comments }));
                        } },
                        React.createElement(TextArea, { label: "Pozn\u00E1mka", name: "internalComment", value: formValues.internalComment, onChange: handleInputChange })))))));
};
export default AresUpdateEdit;
