import React, { useState } from "react";
import { Row, Col, Checkbox, Button } from "@folio/stripes/components";
import { formatDate } from "../../utils/formatDate";
import CustomAccordionSet from "../../components/CustomAccordionSet";
import RenderField from "../../components/RenderField";
var PublisherDetail = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var publisher = _a.publisher;
    var _t = useState(true), expandAll = _t[0], setExpandAll = _t[1];
    var contactTypeTranslations = {
        EMAIL: "E-mail",
        WEB: "Web",
        PHONE: "Telefon",
        DATA_BOX: "Datová schránka",
    };
    var hasMailingAddress = ((_b = publisher.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) ||
        ((_c = publisher.mailingAddress) === null || _c === void 0 ? void 0 : _c.postalCode) ||
        ((_d = publisher.mailingAddress) === null || _d === void 0 ? void 0 : _d.municipality);
    var hasContacts = publisher.contacts && publisher.contacts.length > 0;
    var hasGuarantors = publisher.guarantors && publisher.guarantors.length > 0;
    var hasAlternativeNames = publisher.alternativeNames && publisher.alternativeNames.length > 0;
    var accordions = [
        {
            id: "general",
            label: "Obecné informace",
            content: (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "I\u010CO", value: publisher.companyNumber })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "DI\u010C", value: publisher.taxNumber }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "N\u00E1zev", value: publisher.name })),
                    React.createElement(Col, { xs: 12, md: 6 }, publisher.legalForm === "NATURAL" && (React.createElement(RenderField, { label: "Datum narozen\u00ED", value: publisher.birthDate })))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "Dopln\u011Bk k n\u00E1zvu", value: publisher.nameSuffix })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "Kvalifik\u00E1tor", value: publisher.qualifier }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "Pr\u00E1vn\u00ED forma", value: publisher.legalForm === "LEGAL"
                                ? "Právnická osoba"
                                : "Fyzická osoba" }))))),
        },
        {
            id: "alternativeNames",
            label: "Alternativní názvy",
            content: hasAlternativeNames ? ((_e = publisher.alternativeNames) === null || _e === void 0 ? void 0 : _e.map(function (altName, index) { return (React.createElement(Row, { key: index },
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "N\u00E1zev", value: altName.name })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Dopln\u011Bk k n\u00E1zvu", value: altName.nameSuffix })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Kvalifik\u00E1tor", value: altName.qualifier })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Typ", value: altName.type, translations: {
                            PREVIOUS: "Předchozí",
                            IMPRINT: "Imprint",
                            ABBREVIATION: "Zkratka",
                            ORGANIZATION_UNIT: "Organizační jednotka",
                            FOREIGN_LANGUAGE: "Cizí jazyk",
                        } })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Neve\u0159ejn\u00FD", value: altName.nonPublic ? "Ano" : "Ne" })))); })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00E9 alternativn\u00ED n\u00E1zvy k zobrazen\u00ED")))),
        },
        {
            id: "address",
            label: "Adresa",
            content: (React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Adresa s\u00EDdla"),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "Ulice a \u010D\u00EDslo", value: (_f = publisher.mainAddress) === null || _f === void 0 ? void 0 : _f.street })),
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "PS\u010C", value: (_g = publisher.mainAddress) === null || _g === void 0 ? void 0 : _g.postalCode })),
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "Obec", value: (_h = publisher.mainAddress) === null || _h === void 0 ? void 0 : _h.municipality }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "S\u00EDdeln\u00ED kraj", value: (_k = (_j = publisher.mainAddress) === null || _j === void 0 ? void 0 : _j.region) === null || _k === void 0 ? void 0 : _k.name }))),
                hasMailingAddress && (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Doru\u010Dovac\u00ED adresa"),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(RenderField, { label: "Ulice a \u010D\u00EDslo", value: (_l = publisher.mailingAddress) === null || _l === void 0 ? void 0 : _l.street })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(RenderField, { label: "PS\u010C", value: (_m = publisher.mailingAddress) === null || _m === void 0 ? void 0 : _m.postalCode })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(RenderField, { label: "Obec", value: (_o = publisher.mailingAddress) === null || _o === void 0 ? void 0 : _o.municipality }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(RenderField, { label: "S\u00EDdeln\u00ED kraj", value: (_q = (_p = publisher.mailingAddress) === null || _p === void 0 ? void 0 : _p.region) === null || _q === void 0 ? void 0 : _q.name }))))))),
        },
        {
            id: "contacts",
            label: "Kontakty",
            content: hasContacts ? ((_r = publisher.contacts) === null || _r === void 0 ? void 0 : _r.map(function (contact, index) { return (React.createElement(Row, { key: index },
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Typ", value: contact.type, translations: contactTypeTranslations })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Hodnota", value: contact.value })))); })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00E9 kontakty k zobrazen\u00ED")))),
        },
        {
            id: "guarantors",
            label: "Garanti",
            content: hasGuarantors ? ((_s = publisher.guarantors) === null || _s === void 0 ? void 0 : _s.map(function (guarantor, index) {
                var _a;
                return (React.createElement(React.Fragment, { key: index },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(RenderField, { label: "Jm\u00E9no", value: guarantor.firstName })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(RenderField, { label: "P\u0159\u00EDjmen\u00ED", value: guarantor.lastName }))), (_a = guarantor.contacts) === null || _a === void 0 ? void 0 :
                    _a.map(function (contact, contactIndex) { return (React.createElement(Row, { key: "contact-".concat(index, "-").concat(contactIndex) },
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(RenderField, { label: "Typ", value: contact.type, translations: contactTypeTranslations })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(RenderField, { label: "Hodnota", value: contact.value })))); })));
            })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00ED garanti k zobrazen\u00ED")))),
        },
        {
            id: "comments",
            label: "Interní poznámka",
            content: (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement(RenderField, { label: "Intern\u00ED pozn\u00E1mka", value: publisher.internalComment })))),
        },
        {
            id: "timestamps",
            label: "Časy",
            content: (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas registrace", value: formatDate(publisher.createTime) })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas aktivace", value: formatDate(publisher.activationTime) }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas potvrzen\u00ED \u00FAdaj\u016F", value: formatDate(publisher.dataConfirmationTime) })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas uzav\u0159en\u00ED", value: formatDate(publisher.closeTime) }))))),
        },
    ];
    var handleToggleExpandAll = function (expandAll) {
        setExpandAll(expandAll);
    };
    var toggleExpandCollapseAll = function () {
        setExpandAll(!expandAll);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null,
            publisher.name,
            publisher.nameSuffix ? ", ".concat(publisher.nameSuffix) : ""),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
        React.createElement(CustomAccordionSet, { accordions: accordions, expandAll: expandAll, onToggleExpandAll: handleToggleExpandAll }),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(Checkbox, { label: "Souhlas se zve\u0159ejn\u011Bn\u00EDm", checked: false, disabled: true })))));
};
export default PublisherDetail;
