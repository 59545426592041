var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox, Button } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { fetchAddressCode } from "../../api/publisherService";
import { formatDate } from "../../utils/formatDate";
import CustomAccordionSet from "../../components/CustomAccordionSet";
import RenderField from "../../components/RenderField";
var AresUpdatesDetail = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var ares = _a.ares;
    var ky = useOkapiKy();
    var _t = useState(true), expandAll = _t[0], setExpandAll = _t[1];
    var _u = useState(null), addressData = _u[0], setAddressData = _u[1];
    var contactTypeTranslations = {
        EMAIL: "E-mail",
        WEB: "Web",
        PHONE: "Telefon",
        DATA_BOX: "Datová schránka",
    };
    useEffect(function () {
        var loadAddress = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fetchAddressCode(ky, (_a = ares.data.addressCode) !== null && _a !== void 0 ? _a : "")];
                    case 1:
                        data = _b.sent();
                        setAddressData(data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.error("Failed to fetch address data:", error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        loadAddress();
    }, [ares.data.addressCode]);
    var hasMailingAddress = ((_b = ares.publisher.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) ||
        ((_c = ares.publisher.mailingAddress) === null || _c === void 0 ? void 0 : _c.postalCode) ||
        ((_d = ares.publisher.mailingAddress) === null || _d === void 0 ? void 0 : _d.municipality);
    var hasContacts = ares.publisher.contacts && ares.publisher.contacts.length > 0;
    var accordions = [
        {
            id: "ares",
            label: "Data z resjtříku",
            content: (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "I\u010CO", value: ares.data.companyNumber })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "Jm\u00E9no", value: ares.data.name }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "DI\u010C", value: ares.data.taxNumber })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "Pr\u00E1vn\u00ED forma", value: ares.data.legalForm }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "Ulice a \u010D\u00EDslo", value: addressData === null || addressData === void 0 ? void 0 : addressData.street })),
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "PS\u010C", value: addressData === null || addressData === void 0 ? void 0 : addressData.postalCode })),
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "Obec", value: addressData === null || addressData === void 0 ? void 0 : addressData.municipality }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "S\u00EDdeln\u00ED kraj", value: (_f = (_e = ares.publisher.mailingAddress) === null || _e === void 0 ? void 0 : _e.region) === null || _f === void 0 ? void 0 : _f.name }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas editace", value: formatDate(ares.data.updateTime) }))))),
        },
        {
            id: "general",
            label: "Obecné informace",
            content: (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "I\u010CO", value: ares.publisher.companyNumber })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "DI\u010C", value: ares.publisher.taxNumber }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "N\u00E1zev", value: ares.publisher.name })),
                    React.createElement(Col, { xs: 12, md: 6 }, ares.publisher.legalForm === "NATURAL" && (React.createElement(RenderField, { label: "Datum narozen\u00ED", value: ares.publisher.birthDate })))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "Dopln\u011Bk k n\u00E1zvu", value: ares.publisher.nameSuffix })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "Kvalifik\u00E1tor", value: ares.publisher.qualifier }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "Pr\u00E1vn\u00ED forma", value: ares.publisher.legalForm === "LEGAL"
                                ? "Právnická osoba"
                                : "Fyzická osoba" }))))),
        },
        {
            id: "address",
            label: "Adresa",
            content: (React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Adresa s\u00EDdla"),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "Ulice a \u010D\u00EDslo", value: (_g = ares.publisher.mainAddress) === null || _g === void 0 ? void 0 : _g.street })),
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "PS\u010C", value: (_h = ares.publisher.mainAddress) === null || _h === void 0 ? void 0 : _h.postalCode })),
                    React.createElement(Col, { xs: 12, md: 4 },
                        React.createElement(RenderField, { label: "Obec", value: (_j = ares.publisher.mainAddress) === null || _j === void 0 ? void 0 : _j.municipality }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "S\u00EDdeln\u00ED kraj", value: (_l = (_k = ares.publisher.mainAddress) === null || _k === void 0 ? void 0 : _k.region) === null || _l === void 0 ? void 0 : _l.name }))),
                hasMailingAddress && (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Doru\u010Dovac\u00ED adresa"),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(RenderField, { label: "Ulice a \u010D\u00EDslo", value: (_m = ares.publisher.mailingAddress) === null || _m === void 0 ? void 0 : _m.street })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(RenderField, { label: "PS\u010C", value: (_o = ares.publisher.mailingAddress) === null || _o === void 0 ? void 0 : _o.postalCode })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(RenderField, { label: "Obec", value: (_p = ares.publisher.mailingAddress) === null || _p === void 0 ? void 0 : _p.municipality }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(RenderField, { label: "S\u00EDdeln\u00ED kraj", value: (_r = (_q = ares.publisher.mailingAddress) === null || _q === void 0 ? void 0 : _q.region) === null || _r === void 0 ? void 0 : _r.name }))))))),
        },
        {
            id: "contacts",
            label: "Kontakty",
            content: hasContacts ? ((_s = ares.publisher.contacts) === null || _s === void 0 ? void 0 : _s.map(function (contact, index) { return (React.createElement(Row, { key: index },
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Typ", value: contact.type, translations: contactTypeTranslations })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Hodnota", value: contact.value })))); })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00E9 kontakty k zobrazen\u00ED")))),
        },
        {
            id: "comments",
            label: "Interní poznámka",
            content: (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement(RenderField, { label: "Intern\u00ED pozn\u00E1mka", value: ares.publisher.internalComment })))),
        },
        {
            id: "timestamps",
            label: "Časy",
            content: (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas registrace", value: formatDate(ares.publisher.createTime) })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas aktivace", value: formatDate(ares.publisher.activationTime) }))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas potvrzen\u00ED \u00FAdaj\u016F", value: formatDate(ares.publisher.dataConfirmationTime) })),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(RenderField, { label: "\u010Cas uzav\u0159en\u00ED", value: formatDate(ares.publisher.closeTime) }))))),
        },
    ];
    var toggleExpandCollapseAll = function () {
        setExpandAll(!expandAll);
    };
    var handleToggleExpandAll = function (expandAll) {
        setExpandAll(expandAll);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null,
            ares.publisher.name,
            ares.publisher.nameSuffix ? ", ".concat(ares.publisher.nameSuffix) : ""),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
        React.createElement(CustomAccordionSet, { accordions: accordions, expandAll: expandAll, onToggleExpandAll: handleToggleExpandAll }),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(Checkbox, { label: "Souhlas se zve\u0159ejn\u011Bn\u00EDm", checked: false, disabled: true })))));
};
export default AresUpdatesDetail;
