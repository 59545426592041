import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { Redirect } from "react-router";
import PublisherList from "./PublisherList";
import PublisherEdit from "./PublisherEdit";
import PublisherCreate from "./PublisherCreate";
import PublisherClose from "./PublisherClose";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
function Publishers() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var activeRoles = useActiveRoles(useOkapiKy());
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, render: function () { return React.createElement(PublisherList, null); } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherCreate, null)) : (React.createElement(Redirect, { to: "".concat(path) }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherEdit, { status: "EDIT" })) : (React.createElement(Redirect, { to: "".concat(path) }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/close/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherClose, { internalNote: "" })) : (React.createElement(Redirect, { to: "".concat(path) }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/approve/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherEdit, { status: "APPROVE" })) : (React.createElement(Redirect, { to: "".concat(path) }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/reject/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherEdit, { status: "REJECT" })) : (React.createElement(Redirect, { to: "".concat(path) }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/restore/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherEdit, { status: "RESTORE" })) : (React.createElement(Redirect, { to: "".concat(path) }));
            } })));
}
export default Publishers;
